import SettingsIcon from "@material-ui/icons/Settings";
import DescriptionIcon from "@material-ui/icons/Description";
import ApartmentIcon from "@material-ui/icons/Apartment";
import SmokingRoomsIcon from "@material-ui/icons/SmokingRooms";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import GradeIcon from "@material-ui/icons/Grade";
import BatteryStdIcon from "@material-ui/icons/BatteryStd";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";

export const LIST_ICONS = [
    {
        ten: "Cài đặt",
        icon: <SettingsIcon fontSize="small" />,
    },
    {
        ten: "Tệp tin",
        icon: <DescriptionIcon fontSize="small" />,
    },
    {
        ten: "Căn hộ, chung cư",
        icon: <ApartmentIcon fontSize="small" />,
    },
    {
        ten: "Thuốc lá",
        icon: <SmokingRoomsIcon fontSize="small" />,
    },
    {
        ten: "Rượu",
        icon: <LocalBarIcon fontSize="small" />,
    },
    {
        ten: "Ăn uống",
        icon: <FastfoodIcon fontSize="small" />,
    },
    {
        ten: "Chứng nhận",
        icon: <CardMembershipIcon fontSize="small" />,
    },
    {
        ten: "Ngôi sao",
        icon: <GradeIcon fontSize="small" />,
    },
    {
        ten: "Chai",
        icon: <BatteryStdIcon fontSize="small" />,
    },
    {
        ten: "Cúp",
        icon: <EmojiEventsIcon fontSize="small" />,
    },
];
