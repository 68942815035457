import { DongBoDuLieuComponent } from "pages/DongBoDuLieu/DongBoDuLieuComponent";
import { ImportDauKyComponent } from "pages/importDauKy/ImportDauKyComponent";
import { DongBoCanBoCongChucComponent } from "pages/quanTriHeThong/DongBoCanBo/DongBoCanBoCongChucComponent";
import { DongBoMotCuaComponent } from "pages/quanTriHeThong/DongBoMotCua/DongBoMotCuaComponent";
import { UserManagement } from "pages/user/mgt/userManagement";
import { lazy } from "react";
import { ROUTE_ROOT_ADMIN, ROUTE_ROOT_CN } from "./rootRouter";
import { ROUTE_CN_HOAT_DONG_IN, ROUTE_PAGE_HOAT_DONG_IN } from "./routesHoatDongNganhIn";
import { ROUTE_CN_HOAT_DONG_XBP, ROUTE_PAGE_XUAT_BAN_PHAM } from "./routesHoatDongXuatBanPham";
const HoSoCuaToiMgt = lazy(() =>
    import("../pages/hoSoCuaToi/hoSoCuaToiMgt").then(({ HoSoCuaToiMgt }) => ({
        default: HoSoCuaToiMgt,
    }))
);
const DanhMucBieuMauMgt = lazy(() =>
    import("../pages/quanTriHeThong/danhMucBieuMau/index").then(({ DanhMucBieuMau }) => ({
        default: DanhMucBieuMau,
    }))
);
const HuongDanSuDungComponent = lazy(() => import('../pages/quanTriHeThong/huongDanSuDung/huongDanSuDungComponent').then(({ HuongDanSuDungComponent }) => ({ default: HuongDanSuDungComponent })));

export interface RoutePropsI {
    ten: string;
    giaoDien: JSX.Element;
    duongDan: string;
    routes?: RoutePropsI[];
}
export const ROUTE_PAGE_HOSOCUATOI: RoutePropsI = {
    ten: "HỒ SƠ CỦA TÔI",
    duongDan: "/ho-so-cua-toi",
    giaoDien: <HoSoCuaToiMgt />,
}
export const ROUTE_PAGE: RoutePropsI[] = [
    ROUTE_PAGE_HOSOCUATOI,
    {
        ten: 'Hướng dẫn sử dụng',
        giaoDien: <HuongDanSuDungComponent />,
        duongDan: '/huong-dan-su-dung',
    },
    {
        ten: "Danh mục biểu mẫu",
        duongDan: "/danh-muc-bieu-mau",
        giaoDien: <DanhMucBieuMauMgt />,
    },
    {
        ten: "Đồng bộ một cửa",
        duongDan: "/dong-bo-mot-cua",
        giaoDien: <DongBoMotCuaComponent />,
    },
    {
        ten: 'Đồng bộ dữ liệu',
        giaoDien: <DongBoDuLieuComponent />,
        duongDan: '/dong-bo-du-lieu',
    },
    {
        ten: "Import đầu ky",
        giaoDien: <ImportDauKyComponent />,
        duongDan: "/import-dau-ky",
    },
    {
        ten: 'Đồng bộ người dùng',
        duongDan: '/dong-bo-nguoi-dung',
        giaoDien: <DongBoCanBoCongChucComponent/>,
    },
    {
        ten: 'Quản trị người dùng',
        duongDan: '/quan-tri-nguoi-dung',
        giaoDien: <UserManagement/>,
    },
    
    ...ROUTE_ROOT_ADMIN
];

export const ROUTE_CN: RoutePropsI[] = ROUTE_ROOT_CN;

export const ROUTE_ADMIN: RoutePropsI[] = ROUTE_ROOT_ADMIN;

export const ROUTE_HOAT_DONG_IN: RoutePropsI[] = ROUTE_PAGE_XUAT_BAN_PHAM;

export const ROUTE_XUAT_BAN_PHAM: RoutePropsI[] = ROUTE_CN_HOAT_DONG_XBP;