import { Button, Grid, InputBase, Snackbar, Box } from '@material-ui/core';
import { createFileType, downLoadFile } from 'apis/Common/exportUtil';
import { ExportGroup } from 'components/exportGroup/exportGroup';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BaseResponse } from '../../../apis/baseService';
import { UserManagementService } from '../../../apis/userMgt/userManagementService';
import { AlertMessage, CustomAlert } from '../../../components/commons/alert';
import ButtonExt from '../../../components/commons/button/customButton';
import { ConfirmationDialogRaw } from '../../../components/commons/dialog/confirmDialog';
import { bootstrapTextFieldHook } from '../../../components/commons/input/bootstrap';
import { User } from '../../../model/user';
import { hideLoading, LoadingAction, showLoading } from '../../../redux/actions/applicationAction';
import StringUtil from '../../../utils/stringUtils';
import { DataListUser } from './dataListUser';
import { UserEditDialog } from './userEditDialog';
import IconButton from "@material-ui/core/IconButton";
import { MSExcelIcon, MSWordIcon, PDFIcon } from 'components/commons/icons/icons';
import { green } from "@material-ui/core/colors";

export const UserManagement = () => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openUnlockUserDlg, setOpenUnlockUserDlg] = useState(false);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);

    const [seachQuery, setSearchQuery] = useState<string>('');
    const [dataList, setDataList] = useState<User[]>([]);
    const [itemSeleted, setItemSeleted] = useState<User>({} as User);

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);

    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const loadDataTable = async () => {
        dispatch(showLoading());
        await new UserManagementService().getUser(seachQuery, page * size, size).then((p) => {
            setDataList(p.rows);
            setTotal(p.total);
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            dispatch(hideLoading());
        });
    }
    useEffect(() => {
        loadDataTable();
    }, [page, size]);// eslint-disable-line react-hooks/exhaustive-deps

    const doSearch = () => {
        loadDataTable();
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSize(Number(event.target.value));
    }

    const handleSubmitData = (resp: BaseResponse) => {
        if (resp.isSuccess) {
            setOpenDialog(false);
            setPage(0);
            loadDataTable();
            showMessageAlert(resp.message);
        } else {
            showMessageAlert(resp.message, false);
        }
    }

    const showEditDialog = (item: User) => {
        dispatch(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatch(hideLoading());
    }

    const handleDeleteItem = (userId: string) => {
        if (!StringUtil.isNullOrEmty(userId)) {
            setItemSeleted({ userId: userId } as User);
            setOpenDeleteUserDlg(true);
        }
    }

    const handleUnlockUser = (userId: string, userLocked: boolean) => {
        if (!StringUtil.isNullOrEmty(userId) && userLocked) {
            setItemSeleted({ userId: userId } as User);
            setOpenUnlockUserDlg(true);
        }
    }

    const handleCloseUnlockUser = async (isOk?: boolean) => {
        let isReloadTable = false;
        if (Boolean(isOk)) {
            if (!StringUtil.isNullOrEmty(itemSeleted.userId)) {
                dispatch(showLoading());
                await new UserManagementService().unlockedUser(itemSeleted.userId).then((p) => {
                    isReloadTable = p.data;
                }).finally(() => {
                    dispatch(hideLoading());
                });
            }
        }
        setOpenUnlockUserDlg(false);
        if (isReloadTable) {
            loadDataTable();
        }
    };

    const handleCloseDeleteUser = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            if (!StringUtil.isNullOrEmty(itemSeleted.userId)) {
                dispatch(showLoading());
                await new UserManagementService().deleteUser(itemSeleted.userId).then((p) => {
                    if (Boolean(p.data)) {
                        loadDataTable();
                        showMessageAlert('Xóa người dùng thành công');
                    } else {
                        showMessageAlert('Xóa người dùng không thành công', false);
                    }
                }).catch(e => {
                    showMessageAlert(e, false);
                }).finally(() => {
                    dispatch(hideLoading());
                });
            }
        }
        setOpenDeleteUserDlg(false);
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }))
    }

    async function handleExportFile(downloadType: string) {
        dispatch(showLoading());
        await new UserManagementService().exportUser(seachQuery, downloadType).then((resp) => {
            if (resp && resp.data) {
                downLoadFile(resp?.data, createFileType(downloadType), 'Danh_sach_nguoi_dung');
            }
        }).finally(() => {
            dispatch(hideLoading());
        });
    }

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>Quản lý người dùng hệ thống</span>
            </div>
            <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputBase fullWidth placeholder="Nhập nội dung tìm kiếm" classes={bootstrapTextFieldHook.useInput()}
                            inputProps={{ 'aria-label': 'description' }} value={seachQuery}
                            onChange={(e) => setSearchQuery(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} className="button-group-center">
                        <Button variant="contained" color="primary" disableElevation
                            onClick={doSearch}>
                            Tìm kiếm
                        </Button>
                        <ButtonExt variant="contained" color="warning" disableElevation
                            onClick={() => showEditDialog({ isActive: false, isResetPassword: false } as User)}>
                            Thêm mới
                        </ButtonExt>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton onClick={() => { handleExportFile('docx')}}>
                                    <MSWordIcon color="primary" />
                                </IconButton>
                                <IconButton onClick={() => { handleExportFile('xlsx')}}  disableRipple aria-label="export-excel">
                                    <MSExcelIcon style={{ color: green[500] }} />
                                </IconButton>
                                <IconButton onClick={() => { handleExportFile('pdf')}} disableRipple aria-label="export-pdf">
                                    <PDFIcon color="secondary" />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div>
                    <DataListUser dataList={dataList} deleteItem={handleDeleteItem} unlockUser={handleUnlockUser}
                        pageProps={{
                            page: page, count: total, rowsPerPage: size,
                            onChangePage: handleOnChangePage,
                            onChangeRowsPerPage: handOnChangeRowsPerPage
                        }}
                        modalEdit={{ open: true, openEditDialog: showEditDialog }} />
                    {
                        openDialog &&
                        <UserEditDialog openDialog={openDialog} onDialogClose={() => { setOpenDialog(false) }}
                            itemEdit={itemSeleted} onSubmitData={handleSubmitData} />
                    }
                </div>

                <ConfirmationDialogRaw id="confirm-dialog-unlock-user" keepMounted open={openUnlockUserDlg}
                    title="Mở khóa tài khoản người dùng"
                    content="Bạn có đồng ý thực hiện hành động này?"
                    value={itemSeleted.userId} onClose={handleCloseUnlockUser}
                />
                <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openDeleteUserDlg}
                    title="Xóa tài khoản người dùng"
                    content="Bạn có đồng ý xóa tài khoản này?"
                    value={itemSeleted.userId} onClose={handleCloseDeleteUser}
                />
                <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                </Snackbar>
            </form>
        </div >
    )
}