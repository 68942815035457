import { PasswordManagement } from "pages/user/passwordMgt/passwordManagement";
import { ProfileUpdate } from "pages/user/update/profileUpdate";
import { RoutePropsI } from "routes";

export const ROUTE_GUEST_ADMIN : RoutePropsI[] =[
    {
        ten: 'Thông tin người dùng',
        giaoDien: <ProfileUpdate/>,
        duongDan: '/user-info',
    },
    {
        ten: 'Quản lý mật khẩu',
        giaoDien: <PasswordManagement/>,
        duongDan: '/password-mgt',
    },
]

export const ROUTE_GUEST: RoutePropsI[] = [

    ...ROUTE_GUEST_ADMIN
];