import { lazy } from "react";
import { Type } from "apis/danhMuc/danhMucDungRiengService";

import { RoutePropsI } from "routes";

const DanhMucDungRiengMgt = lazy(() => import("pages/danhMuc/dungRieng/danhMucDungRiengMgt").then(({ DanhMucDungRiengMgt }) => ({ default: DanhMucDungRiengMgt })));


export const ROUTE_ADMIN_DANH_MUC: RoutePropsI[] = [
	 {
		    ten: "Danh mục thể loại",
		    giaoDien: <DanhMucDungRiengMgt type={Type.THE_LOAI} />,
		    duongDan: "/danh-muc-the-loai",
		},
		{
		    ten: "Danh mục ngôn ngữ",
		    giaoDien: <DanhMucDungRiengMgt type={Type.NGON_NGU} />,
		    duongDan: "/danh-muc-ngon-ngu",
		},
		{
		    ten: "Danh mục nhà cung cấp",
		    giaoDien: <DanhMucDungRiengMgt type={Type.NHA_CUNG_CAP} />,
		    duongDan: "/danh-muc-nha-cung-cap",
		},
		{
		    ten: "Danh mục nhà xuất bản",
		    giaoDien: <DanhMucDungRiengMgt type={Type.NHA_XUAT_BAN} />,
		    duongDan: "/danh-muc-nha-xuat-ban",
		},
		{
		    ten: "Danh mục xuất xứ",
		    giaoDien: <DanhMucDungRiengMgt type={Type.XUAT_XU} />,
		    duongDan: "/danh-muc-xuat-xu",
		},
		{
		    ten: "Danh mục nước sản xuất",
		    giaoDien: <DanhMucDungRiengMgt type={Type.NUOC_SAN_XUAT} />,
		    duongDan: "/danh-muc-nuoc-san-xuat",
		},
		{
		    ten: "Danh mục hãng sản xuất",
		    giaoDien: <DanhMucDungRiengMgt type={Type.HANG_SAN_XUAT} />,
		    duongDan: "/danh-muc-hang-san-xuat",
		},
]