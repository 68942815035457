import { lazy } from "react";
import { RoutePropsI } from "routes";
const ThongTinChiTietGPIXBP = lazy(() => import("pages/hoatDongXuatBanPham/giayPhep/giayPhepXBP/thongTinChiTiet").then(({ ThongTinChiTietGPIXBP }) => ({ default: ThongTinChiTietGPIXBP })));
const ThongTinChiTietXBKKKD = lazy(() => import("pages/hoatDongXuatBanPham/giayPhep/giayPhepKhongKD").then(({ ThongTinChiTietXBKKKD }) => ({ default: ThongTinChiTietXBKKKD })));
const CapPhepXBPKhongKD = lazy(() => import("pages/hoatDongXuatBanPham/capPhepKhongKD").then(({ CapPhepXBPKhongKD }) => ({ default: CapPhepXBPKhongKD })));
const CapPhepTaiLieuKhongKD = lazy(() => import("pages/hoatDongXuatBanPham/capPhepTaiLieuKhongKD").then(({ CapPhepTaiLieuKhongKD }) => ({ default: CapPhepTaiLieuKhongKD })));
const ThongTinChiTietTLHC = lazy(() => import("pages/hoatDongXuatBanPham/giayPhep/giayPhepTLHC").then(({ ThongTinChiTietTLHC }) => ({ default: ThongTinChiTietTLHC })));
const ThongTinChiTietTaiLieuKKD = lazy(() => import("pages/hoatDongXuatBanPham/giayPhep/giayPhepTaiLieuKhongKD").then(({ ThongTinChiTietTaiLieuKKD }) => ({ default: ThongTinChiTietTaiLieuKKD })));
const CapNhatCongVan = lazy(() => import("pages/hoatDongXuatBanPham/giayPhep/giayPhepTaiLieuKhongKD/capNhapCongVan").then(({ CapNhatCongVan }) => ({ default: CapNhatCongVan })));
const NhapDauKyGP = lazy(() => import("pages/hoatDongXuatBanPham/giayPhep/giayPhepXBP/nhapDauKyGP").then(({ NhapDauKyGP }) => ({ default: NhapDauKyGP })));
const DanhSachGiayPhep = lazy(() => import("pages/hoatDongXuatBanPham").then(({ DanhSachGiayPhep }) => ({ default: DanhSachGiayPhep })));
const CapPhepToChucTrienLamHoiChoXBP = lazy(() => import("pages/hoatDongXuatBanPham/capPhepTLHC").then(({ CapPhepToChucTrienLamHoiChoXBP }) => ({ default: CapPhepToChucTrienLamHoiChoXBP })));
const ThongKeHoatDongXBP = lazy(() => import("pages/hoatDongXuatBanPham/thongKeHoatDongXBP").then(({ ThongKeHoatDongXBP }) => ({ default: ThongKeHoatDongXBP })));
const BieuDoThongKeHoatDong = lazy(() => import("pages/hoatDongXuatBanPham/bieuDo"));
const BaoCaoHoatDongXBP = lazy(() => import("pages/hoatDongXuatBanPham/baoCaoHoatDongXBP").then(({ BaoCaoHoatDongXBP }) => ({ default: BaoCaoHoatDongXBP })));
const ThuHoiInXuatBanPham = lazy(() => import("pages/hoatDongXuatBanPham/giayPhep/giayPhepXBP/thuHoiGP").then(({ ThuHoiInXuatBanPham }) => ({ default: ThuHoiInXuatBanPham })));
const CapDoiGPHoatDongInXBP = lazy(() => import("pages/hoatDongXuatBanPham/capDoiGPXBP").then(({ CapDoiGPHoatDongInXBP }) => ({ default: CapDoiGPHoatDongInXBP })));
const DauKyXBPKhongKD = lazy(() => import("pages/hoatDongXuatBanPham/capPhepKhongKD/dauKy").then(({ DauKyXBPKhongKD }) => ({ default: DauKyXBPKhongKD })));
const DauKyToChucTrienLamHoiChoXBP = lazy(() => import("pages/hoatDongXuatBanPham/capPhepTLHC/dauKy").then(({ DauKyToChucTrienLamHoiChoXBP }) => ({ default: DauKyToChucTrienLamHoiChoXBP })));
const DauKyCapPhepTaiLieuKhongKD = lazy(() => import("pages/hoatDongXuatBanPham/capPhepTaiLieuKhongKD/dauKy").then(({ DauKyCapPhepTaiLieuKhongKD }) => ({ default: DauKyCapPhepTaiLieuKhongKD })));
const CapNhatCongVanDauKy = lazy(() => import("pages/hoatDongXuatBanPham/capPhepTaiLieuKhongKD/dauKy/capNhapCongVan").then(({ CapNhatCongVanDauKy }) => ({ default: CapNhatCongVanDauKy })));

export const URL_XBP = "/xbp";

export const ROUTE_DAU_KY_HOAT_DONG_SP_KHONG_KINH_DOANH = {
    ten: "Nhập đầu kỳ nhập khẩu bản phẩm không kinh doanh",
    duongDan: `${URL_XBP}/nhap-dau-ky-hoat-khong-kinh-doanh`,
    giaoDien: <DauKyXBPKhongKD />,
}

export const ROUTE_DAU_KY_TO_CHU_HOI_CHO_TRIEN_LAMXCP = {
    ten: "Nhập đầu kỳ cấp phép tổ chức triển lãm, hội chợ xuất bản phẩm",
    duongDan: `${URL_XBP}/nhap-dau-ky-cap-phep-to-chuc-trien-lam-hoi-cho`,
    giaoDien: <DauKyToChucTrienLamHoiChoXBP />,
}

export const ROUTE_DAU_KY_CAP_PHEP_TAI_LIEU_KHONG_KINH_DOANH = {
    ten: "Nhập đầu kỳ cấp giấy phép nhập khẩu xuất bản phẩm không kinh doanh ",
    duongDan: `${URL_XBP}/nhap-dau-ky-cap-phep-tai-lieu-khong-kinh-doanh`,
    giaoDien: <DauKyCapPhepTaiLieuKhongKD/>,
}

export const ROUTE_CAP_CONG_VAN_DAU_KY = {
    ten: "Cập nhật công văn đầu kỳ ",
    duongDan: `${URL_XBP}/nhap-dau-ky-cap-nhat-cong-van`,
    giaoDien: <CapNhatCongVanDauKy/>,
}

export const ROUTE_PAGE_XUAT_BAN_PHAM: RoutePropsI[] = [
    {
        ten: "Thông tin chi tiết in xuất bản phẩm",
        duongDan: `${URL_XBP}/thong-tin-chi-tiet-in-xuat-ban-pham`,
        giaoDien: <ThongTinChiTietGPIXBP />,
    },
    {
        ten: "Thông tin chi tiết xuất bản phẩm không kinh doanh",
        duongDan: `${URL_XBP}/thong-tin-chi-tiet-giay-phep-nhap-khau-xuat-ban-pham`,
        giaoDien: <ThongTinChiTietXBKKKD />,
    },
    {
        ten: "Thông tin chi tiết triển lãm hội chợ",
        duongDan: `${URL_XBP}/thong-tin-chi-tiet-trien-lam-hoi-cho`,
        giaoDien: <ThongTinChiTietTLHC />,
    },
    {
        ten: "Thông tin chi tiết tài liệu không kinh doanh",
        duongDan: `${URL_XBP}/thong-tin-chi-tiet-tai-lieu-khong-kinh-doanh`,
        giaoDien: <ThongTinChiTietTaiLieuKKD />,
    },
    {
        ten: "Cập nhật công văn",
        duongDan: `${URL_XBP}/cap-nhat-cong-van`,
        giaoDien: <CapNhatCongVan />,
    },
    {
        ten: "Nhập đầu kỳ giấy phép",
        duongDan: `${URL_XBP}/nhap-dau-ky-hoat-dong-xuat-ban-pham`,
        giaoDien: <NhapDauKyGP />,
    },
    {
        ten: "Thu hồi giấy phép hoạt động in",
        duongDan: `${URL_XBP}/thu-hoi-giay-phep-hoat-dong-in`,
        giaoDien: <ThuHoiInXuatBanPham />,
    },
    {
        ten: "Danh sách giấy phép",
        duongDan: `${URL_XBP}/danh-sach-giay-phep`,
        giaoDien: <DanhSachGiayPhep />,
    },
    {
        ten: "Biều đồ thống kê xuất bản phẩm",
        duongDan: `${URL_XBP}/bieu-do-thong-ke`,
        giaoDien: <BieuDoThongKeHoatDong />,
    },
    {
        ten: "Báo cáo hoạt động xuất bản phẩm",
        duongDan: `${URL_XBP}/bao-cao-xuat-ban-pham`,
        giaoDien: <BaoCaoHoatDongXBP />,
    },
    {
        ten: "Thông kê hoạt động xuất bản phẩm",
        duongDan: `${URL_XBP}/thong-ke-xuat-ban-pham`,
        giaoDien: <ThongKeHoatDongXBP />,
    },
    ROUTE_CAP_CONG_VAN_DAU_KY
];



export const ROUTE_CN_HOAT_DONG_XBP: RoutePropsI[] = [
    {
        ten: "Cấp đổi giấy phép hoạt động in xuất bản phẩm",
        duongDan: `${URL_XBP}/cap-doi-giay-phep-hoat-dong-in`,
        giaoDien: <CapDoiGPHoatDongInXBP />,
    },
    {
        ten: "Cấp giấy phép nhập khẩu bản phẩm không kinh doanh",
        duongDan: `${URL_XBP}/cap-giay-phep-hoat-khong-kinh-doanh`,
        giaoDien: <CapPhepXBPKhongKD />,
    },
    {
        ten: "Cấp phép tổ chức triển lãm, hội chợ xuất bản phẩm",
        duongDan: `${URL_XBP}/cap-phep-to-chuc-trien-lam-hoi-cho`,
        giaoDien: <CapPhepToChucTrienLamHoiChoXBP />,
    },
    {
        ten: "Cấp phép xuất bản tài liệu không kinh doanh",
        duongDan: `${URL_XBP}/cap-phep-tai-lieu-khong-kinh-doanh`,
        giaoDien: <CapPhepTaiLieuKhongKD />,
    },
    ROUTE_DAU_KY_HOAT_DONG_SP_KHONG_KINH_DOANH,
    ROUTE_DAU_KY_TO_CHU_HOI_CHO_TRIEN_LAMXCP,
    ROUTE_DAU_KY_CAP_PHEP_TAI_LIEU_KHONG_KINH_DOANH
];

