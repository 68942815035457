import { RoutePropsI } from "routes";
import { ROUTE_GUEST_ADMIN } from "./guest";
import { ROUTE_ADMIN_DANH_MUC } from "./routesDanhMuc";
import { ROUTE_PAGE_HOAT_DONG_IN, ROUTE_CN_HOAT_DONG_IN } from "./routesHoatDongNganhIn";
import { ROUTE_PAGE_XUAT_BAN_PHAM, ROUTE_CN_HOAT_DONG_XBP } from "./routesHoatDongXuatBanPham";
import { ROUTE_ADMIN_USER_MGT } from "./routesUserMgt";

export const ROUTE_ROOT_CN: RoutePropsI[] = [
    ...ROUTE_CN_HOAT_DONG_IN,
    ...ROUTE_CN_HOAT_DONG_XBP,
    ...ROUTE_PAGE_XUAT_BAN_PHAM,
    ...ROUTE_PAGE_HOAT_DONG_IN,
    ...ROUTE_GUEST_ADMIN

];

export const ROUTE_ROOT_ADMIN: RoutePropsI[] = [
    ...ROUTE_ADMIN_USER_MGT, ...ROUTE_ADMIN_DANH_MUC,
    ...ROUTE_PAGE_HOAT_DONG_IN,
    ...ROUTE_PAGE_XUAT_BAN_PHAM,
];
