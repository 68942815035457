import { IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";

export interface EndAdornmentSearchProps {
    onClick?: () => void;
    color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
}

const useStyles = makeStyles({
    root: {
        position: "absolute",
        right: "10px",
    },
});

const EndAdornmentSearch: React.FC<EndAdornmentSearchProps> = ({ onClick, color }) => {
    const classes = useStyles();

    return (
        <InputAdornment position="end" classes={classes}>
            <IconButton edge="end" disableRipple disableTouchRipple disableFocusRipple onClick={onClick}>
                <SearchIcon fontSize="small" color={color ? color : "primary"} />
            </IconButton>
        </InputAdornment>
    );
};

export default EndAdornmentSearch;
