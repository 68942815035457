import { ThemedSuspense } from "components/themedSuspense";
import { Suspense } from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { ROUTE_PAGE_PUBLIC } from "routes/routerPublic";
export const PublicPage = () => {
    let { path, url } = useRouteMatch();
    return (
        <>
            <Suspense fallback={<ThemedSuspense />}>
                <Switch>
                    {ROUTE_PAGE_PUBLIC.map((component, i) => (
                        <Route key={i} exact path={`${path}${component.duongDan}`} component={() => component.giaoDien} />
                    ))}
                </Switch>
            </Suspense>
        </>
    );
};
