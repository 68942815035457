import { RemenberAction } from "redux/actions/remenberActions";

type RemenberState = {
    remenberDSXBP: String;
    remenberDSHoatDongNganhIn: String;
};

const initialState: RemenberState = {
    remenberDSXBP: "1",
    remenberDSHoatDongNganhIn: "0",
};

export function rememberReducer(state: RemenberState = initialState, action: RemenberAction) {
    switch (action.type) {
        case "SET_DS_XBP": {
            return { ...state, remenberDSXBP: action.value };
        }
        case "SET_DS_HOAT_DONG_NGANH_IN": {
            return { ...state, remenberDSHoatDongNganhIn: action.value };
        }
        default:
            return state;
    }
}
