import AXIOS_INSTANCE from "..";
import { DongBoDanhMucMotCuaRequest } from "./model/MotCuaModel";

export default class MotCuaService {
    constructor() { }
    
    dongBoMotCua = (request: DongBoDanhMucMotCuaRequest) => {
        return AXIOS_INSTANCE.post('mot-cua/dong-bo-danh-muc', request);
    }

    dongBoCanBoCongChuc = (maCoQuan: string) => {
        return AXIOS_INSTANCE.post('mot-cua/dong-bo-can-bo-cong-chuc',  {maCoQuan},{timeout : 600000});
    }

    dongBoGiayPheps = (loaiGiayPhep: string) => {
        return AXIOS_INSTANCE.post('mot-cua/get-danh-sach-so-giay-phep',  {loaiGiayPhep},{timeout : 600000});
    }

}


