import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, MenuProps } from "@material-ui/core";
import ViewListIcon from "@material-ui/icons/ViewList";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            backgroundColor: theme.palette.info.light,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

interface MenuDropI {
    onDelete: (data: any) => void;
    item: any;
}

const MenuDrop: React.FC<MenuDropI> = ({ onDelete, item }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    return (
        <Box display="flex" justifyContent="center">
            <IconButton
                color="default"
                aria-label="edit"
                size="small"
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                }}
            >
                <ViewListIcon />
            </IconButton>
            <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl} keepMounted onClose={() => setAnchorEl(null)} elevation={0}>
                <StyledMenuItem disableRipple>
                    <IconButton size="small">
                        <EditIcon fontSize="small" color="primary" />
                    </IconButton>
                    Chỉnh sửa
                </StyledMenuItem>
                <StyledMenuItem
                    disableRipple
                    onClick={
                        onDelete
                            ? () => {
                                  onDelete(item);
                                  setAnchorEl(null);
                              }
                            : () => {}
                    }
                >
                    <IconButton size="small">
                        <CloseIcon fontSize="small" color="secondary" />
                    </IconButton>
                    Hủy quyết định
                </StyledMenuItem>
                <StyledMenuItem disableRipple>
                    <IconButton size="small">
                        <VisibilityIcon fontSize="small" style={{ color: green[500] }} />
                    </IconButton>
                    Xem chi tiết
                </StyledMenuItem>
            </StyledMenu>
        </Box>
    );
};

export default MenuDrop;
