import { lazy } from "react";
import { RoutePropsI } from "routes";

const NhapDauKyGPHDIN = lazy(() =>
    import("pages/hoatDongNganhIn/giayPhep/giayPhepHoatDongNganhIn/nhapDauKyGP").then(({ NhapDauKyGPHDIN }) => ({ default: NhapDauKyGPHDIN }))
);
const CapGPHoatDongNganhIn = lazy(() =>
    import("pages/hoatDongNganhIn/capPhepHoatDongIn").then(({ CapGPHoatDongNganhIn }) => ({ default: CapGPHoatDongNganhIn }))
);
const DangKyHoatDongCoSoIn = lazy(() =>
    import("pages/hoatDongNganhIn/dangKyHoatDongCSIn").then(({ DangKyHoatDongCoSoIn }) => ({ default: DangKyHoatDongCoSoIn }))
);
const DanhSachGiayPhepHDIn = lazy(() => import("pages/hoatDongNganhIn").then(({ DanhSachGiayPhepHDIn }) => ({ default: DanhSachGiayPhepHDIn })));
const ThongTinChiTietHoatDongNganhIn = lazy(() =>
    import("pages/hoatDongNganhIn/giayPhep/giayPhepHoatDongNganhIn/thongTinChiTiet").then(({ ThongTinChiTietHoatDongNganhIn }) => ({
        default: ThongTinChiTietHoatDongNganhIn,
    }))
);
const ThuHoiGiayPhep = lazy(() =>
    import("pages/hoatDongNganhIn/giayPhep/giayPhepHoatDongNganhIn/thuHoiGP").then(({ ThuHoiGiayPhep }) => ({ default: ThuHoiGiayPhep }))
);
const ThongTinChiTietGPDangKy = lazy(() =>
    import("pages/hoatDongNganhIn/giayPhep/thongTinChiTietGPDangKy").then(({ ThongTinChiTietGPDangKy }) => ({ default: ThongTinChiTietGPDangKy }))
);
const ThongKeNganhIn = lazy(() => import("pages/hoatDongNganhIn/thongKe").then(({ ThongKeNganhIn }) => ({ default: ThongKeNganhIn })));
const BaoCaoGPHoatDongNganhIN = lazy(() =>
    import("pages/hoatDongNganhIn/baoCao").then(({ BaoCaoGPHoatDongNganhIN }) => ({ default: BaoCaoGPHoatDongNganhIN }))
);
const CapLaiGPHoatDongIn = lazy(() => import("pages/hoatDongNganhIn/capLaiHoatDongIn").then(({ CapLaiGPHoatDongIn }) => ({ default: CapLaiGPHoatDongIn })));
const BieuDoThongKeHoatDong = lazy(() => import("pages/hoatDongNganhIn/bieuDo"));
const WrapperMapTraCuu = lazy(() => import("pages/hoatDongNganhIn/traCuuBanDo/wrapperTraCuuBanDo"));

export const URL_HOAT_DONG_IN = "/hoat-dong-in";
export const ROUTE_PAGE_HOAT_DONG_IN: RoutePropsI[] = [
    {
        ten: "Thông tin chi tiết văn bản xác nhận đăng ký hoạt động cơ sở in",
        duongDan: `${URL_HOAT_DONG_IN}/thong-tin-chi-van-ban-xac-nhan-dang-ky-hoat-dong-co-so-in`,
        giaoDien: <ThongTinChiTietGPDangKy />,
    },
    {
        ten: "Thông tin chi tiết hoạt động ngành in",
        duongDan: `${URL_HOAT_DONG_IN}/thong-tin-chi-tiet-hoat-dong-nganh-in`,
        giaoDien: <ThongTinChiTietHoatDongNganhIn />,
    },
    {
        ten: "Nhập đầu kỳ giấy phép hoạt động ngành in",
        duongDan: `${URL_HOAT_DONG_IN}/nhap-dau-ky-giay-phep-hoat-dong-nganh-in`,
        giaoDien: <NhapDauKyGPHDIN />,
    },
    {
        ten: "Thu hồi giấy phép hoạt động ngành in",
        duongDan: `${URL_HOAT_DONG_IN}/thu-hoi-giay-phep-hoat-dong-nganh-in`,
        giaoDien: <ThuHoiGiayPhep />,
    },
    {
        ten: "Danh sách giấy phép hoạt động in",
        duongDan: `${URL_HOAT_DONG_IN}/danh-sach-giay-phep-hoat-dong-in`,
        giaoDien: <DanhSachGiayPhepHDIn />,
    },
    {
        ten: "Đăng ký hoạt động cơ sở in",
        duongDan: `${URL_HOAT_DONG_IN}/dang-ky-hoat-dong-in`,
        giaoDien: <DangKyHoatDongCoSoIn />,
    },
    {
        ten: "Thống kê giấy phép hoạt động ngành in",
        duongDan: `${URL_HOAT_DONG_IN}/thong-ke-giay-phép-hoat-dong-nganh-in`,
        giaoDien: <ThongKeNganhIn />,
    },
    {
        ten: "Báo cáo giấy phép hoạt động ngành in",
        duongDan: `${URL_HOAT_DONG_IN}/bao-cao-giay-phep-hoat-dong-nganh-in`,
        giaoDien: <BaoCaoGPHoatDongNganhIN />,
    },
    {
        ten: "Biểu đồ thống kê hoạt động ngành in",
        duongDan: `${URL_HOAT_DONG_IN}/bieu-do-thong-ke-hoat-dong-nganh-in`,
        giaoDien: <BieuDoThongKeHoatDong />,
    },
    {
        ten: "Tra cứu bản đồ",
        duongDan: `${URL_HOAT_DONG_IN}/tra-cuu-ban-do`,
        giaoDien: <WrapperMapTraCuu />,
    },
];

export const ROUTE_CN_HOAT_DONG_IN: RoutePropsI[] = [
    {
        ten: "Cấp giấy phép hoạt động in",
        duongDan: `${URL_HOAT_DONG_IN}/cap-giay-phep`,
        giaoDien: <CapGPHoatDongNganhIn />,
    },
    {
        ten: "Cấp lại giấy phép hoạt động in",
        duongDan: `${URL_HOAT_DONG_IN}/cap-lai-giay-phep`,
        giaoDien: <CapLaiGPHoatDongIn />,
    },
    {
        ten: "Đăng ký hoạt động cơ sở in",
        duongDan: `${URL_HOAT_DONG_IN}/dang-ky-hoat-dong-co-so-in`,
        giaoDien: <DangKyHoatDongCoSoIn />,
    },
];
