import { Button, Grid, InputBase, InputLabel, NativeSelect, Snackbar } from '@material-ui/core';
import { DongBoDanhMucMotCuaRequest } from 'apis/quanTri/model/MotCuaModel';
import MotCuaService from 'apis/quanTri/MotCuaService';
import { SYSTEM_CODE } from 'configs/systemConfig';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AlertMessage, CustomAlert } from '../../../components/commons/alert';
import { ConfirmationDialogRaw } from '../../../components/commons/dialog/confirmDialog';
import { bootstrapTextFieldHook } from '../../../components/commons/input/bootstrap';
import { OverlayLoading } from '../../../components/overlayLoading/overlayLoading';
import { hideLoading, LoadingAction, showLoading } from '../../../redux/actions/applicationAction';



export const DongBoMotCuaComponent = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const dispatch = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<DongBoDanhMucMotCuaRequest>({ ten: 'get_co_quan', maCoQuan: SYSTEM_CODE });
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }
    const motCuaService = new MotCuaService();
    useEffect(() => {
    }, []);

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }))
    }
    
    const dongBo = () => {
        setOpenConfirm(true);
    }
    const handleDongBo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            dispatch(showLoading());
            motCuaService.dongBoMotCua(data).then((p) => {
                if (p.data) {
                    showMessageAlert('Đồng bộ thành công', true);
                } else {
                    showMessageAlert('Đồng bộ thất bại', false);
                }
            }).catch(e => {
                showMessageAlert(e, false);
            }).finally(() => {
                dispatch(hideLoading());
            });
        }
        setOpenConfirm(false);
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>Đồng bộ một cửa</span>
            </div>
            <Grid container spacing={2} direction="row" alignItems="center">
                <OverlayLoading />
                <Grid item xs={6} className="gridItem">
                    <InputLabel classes={labelStyles}>Loại dữ liệu</InputLabel>
                    <NativeSelect input={<InputBase fullWidth classes={inputStyles} />}
                        value={data.ten} onChange={(e) => setData({ ...data, ten: e.target.value })} >
                        <option value={'get_co_quan'}>Cơ Quan hành chính</option>
                        <option value={'dong_bo_linh_vuc'}>Lĩnh vực</option>
                        <option value={'dong_bo_dich_vu_cong'}>Thủ tục</option>
                        <option value={'lay_danh_sach_loai_van_ban'}>Loại văn bản</option>
                        
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} className="button-group-center">
                    <Button variant="contained" color="primary" disableElevation
                        onClick={dongBo}>
                        Đồng bộ
                        </Button>
                </Grid>
            </Grid>
            <ConfirmationDialogRaw id="confirm-dialog-dong-bo" keepMounted open={openConfirm}
                title="Đồng bộ"
                content="Bạn có đồng ý thực hiện thao tác này?" onClose={handleDongBo}
            />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div>
    )
}