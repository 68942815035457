import { BaseResponse, BaseService, PageRequest, Pagination } from "../baseService";
import axios from "../index";

export interface DanhMucDungRiengDTO {
    id: string;
    name: string;
    description: string;
    isActive: boolean;
    type: Type;
}
export enum Type {
    THE_LOAI = 0,
    NGON_NGU = 1,
    NHA_CUNG_CAP = 2,
    NHA_XUAT_BAN = 3,
    XUAT_XU = 4,
    NUOC_SAN_XUAT = 5,
    HANG_SAN_XUAT = 6
}
export interface DanhMucDungRiengRequest extends PageRequest {
    type: Type;
    isActive?: Boolean;
}
export class DanhMucDungRiengService extends BaseService {

    getDMDungRieng = async (request: DanhMucDungRiengRequest): Promise<Pagination<DanhMucDungRiengDTO[]>> => {
        let result = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        } as Pagination<DanhMucDungRiengDTO[]>;
        await axios.get('/danh-muc/' + request.type + '/search', { params: request }).then(response => {
            if (response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content
                }
            }
        }).catch();
        return result;
    }

    insertOrUpdate = async (request: DanhMucDungRiengDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/danh-muc/' + request.type + '/insertOrUpdate', JSON.stringify(request), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message
            }
        }).catch(() => result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' });
        return result;
    }

    deleteById = async (request: DanhMucDungRiengDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/danh-muc/' + request.type + '/delete', JSON.stringify({ id: request.id }), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message
            }
        }).catch(() => result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' });
        return result;
    }


}
