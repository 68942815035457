import { DanhMucDiaChiDTO } from "model/danhMuc/danhMucDTO";
import { TypeDanhMuc } from "../../hooks/useGetDanhMuc";
import { BaseResponse, BaseService } from "../baseService";
import axios from "../index";

export type DanhMucDTO = {
    id: number;
    ma: string;
    ten: string;
    ext?: any;
};
export type CoQuanDTO = {
    kyHieu: string;
    ten: string;
    ma: string;
    thamGiaHeThong: string;
    hoatDong: boolean;
}
export class DanhMucService extends BaseService {
    getLoaiVanBanByMaCoQuan_HoatDong = async (maCoQuan: string, hoatDong: boolean): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await axios.post('danh-muc/loai-van-ban/get-by-ma-co-quan-hoat-dong', { maCoQuan: maCoQuan, hoatDong: hoatDong }, this.getTokenRequestHeaders())
            .then(response => {
                if (response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.loaiVanBan, ma: '', ten: item.tenLoaiVanBan });
                    });
                }
            }).catch();
        return result;
    }
    getLinhVucByMaCoQuan_HoatDong = async (maCoQuan: string, hoatDong: boolean): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await axios
            .post("danh-muc/linh-vuc/get-by-ma-co-quan-hoat-dong", { maCoQuan: maCoQuan, hoatDong: hoatDong }, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.id, ma: item.ma, ten: item.ten });
                    });
                }
            })
            .catch();
        return result;
    };
    getDMThuTuc = async (maLinhVuc: string, hoatDong: boolean): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await axios
            .post("/danh-muc/thu-tuc", { maLinhVuc: maLinhVuc, hoatDong: hoatDong }, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: 0, ma: item.ma, ten: item.ten });
                    });
                }
            })
            .catch();
        return result;
    };

    getDMLoaiThuTuc = async (): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await axios
            .get("/danh-muc-loai-thu-tuc", this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.id, ma: item.maLoaiThuTuc, ten: item.tenLoaiThuTuc });
                    });
                }
            })
            .catch();
        return result;
    };

    getDMPhongBan = async (): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await axios
            .get("/danh-muc-phong-ban", this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.id, ma: item.maPhongBan, ten: item.tenPhongBan });
                    });
                }
            })
            .catch();
        return result;
    };

    getDanhMuc = async (typeDanhMuc: TypeDanhMuc): Promise<BaseResponse> => {
        let result = {
            isSuccess: false,
        } as BaseResponse;

        await axios.get(`/danh-muc/${typeDanhMuc}/get`).then((response) => {
            if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                result.isSuccess = true;
                result.message = response.data.error_code;
                result.data = response.data.data;
            } else {
                result.message = response.data.error_message;
            }
        });
        return result;
    };

    getDanhMucDC = async (parentCode: String): Promise<BaseResponse> => {
        let result = {
            isSuccess: false,
        } as BaseResponse;
        await axios.post(`/danh-muc/findAllDiaChiCommon`, { parentCode }).then((response) => {
            if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                result.isSuccess = true;
                result.message = response.data.error_code;
                result.data = response.data.data;
            } else {
                result.message = response.data.error_message;
            }
        });
        return result;
    };

    getDanhMucDiaChi = async (parentCode: String): Promise<DanhMucDiaChiDTO[]> => {
        let result: DanhMucDiaChiDTO[] = [];
        await axios.post(`/danh-muc/findAllDiaChiCommon`, { parentCode }).then((response) => {
            if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                result = response.data.data

            }
        }).catch();
        return result;
    };

    getDanhMucDiaChiByCodeAndParentCode = async (code: string, parentCode: string): Promise<DanhMucDiaChiDTO> => {
        let result: DanhMucDiaChiDTO = {name:"", code:""};
        await axios.post(`/danh-muc/findDiaChiByCodeAndParentCode`, { code: code, parentCode: parentCode }).then((response) => {
            if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                result = response.data.data
            }
        }).catch();
        return result;
    }

    getDanhMucCoQuanCode_HoatDong = async (hoatDong: boolean): Promise<any> => {
        let result;
        await axios.post (`/danh-muc-co-quan/findAll`, { hoatDong  :hoatDong })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };
}
