import { BaseService} from "../baseService";
import axios from "../index";
export class DanhSachKhenThuongService extends BaseService {

    downloadFileTemplateTapThe = async (idChuyenNganh: string): Promise<any> => {
        return axios.get("import-dau-ky/download-template/" + idChuyenNganh, {
            responseType: "blob",
        });
    };
}
